import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from '../components/ui/Div'
import Button from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import Image from '../components/ui/Image'
import { colors, smallBoxShadow } from '../components/ui/helpers.js'
import { urlToString } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'

import Layout from '../components/layout'
import SideMenu from '../components/SideMenu'
import DesktopSideBar from '../components/DesktopSideBar'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'

const FullHeightContainer = styled(Flex)`
  min-height: 100vh;
`

const PrivacyPolicy = ({}) => {
  return (
    <Layout>
      <Helmet
        title={'About SMS-Temp | 18+ Countries | 100% Free'}
        lang="en"
        description={
          'Protect your privacy and security with our free temporary phone numbers service. Receive unlimited SMS on exclusive, real SIM numbers.'
        }
      >
        <html lang={'en'} />
      </Helmet>
      <FullHeightContainer flexDirection="column" alignItems="center">
        <Header />

        <Flex maxWidth="900px" pt="60px" flexDirection="column" mb="50px">
          <H1 mb="40px">Privacy Policy </H1>
          <Text fontSize="18px" bold ml="40px" mb="40px">
            Owner contact email: smstempcontact@gmail.com
          </Text>
          <H3 mb="20px" mt="20px">
            Types of Data collected
          </H3>
          <Text>
            Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies; Usage Data.
            Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific
            explanation texts displayed prior to the Data collection. Personal Data may be freely provided by the User, or, in case of Usage Data,
            collected automatically when using this Application. Unless specified otherwise, all Data requested by this Application is mandatory and
            failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application
            specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability
            or the functioning of the Service. Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner. Any
            use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves
            the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the
            Cookie Policy, if available. Users are responsible for any third-party Personal Data obtained, published or shared through this
            Application and confirm that they have the third party's consent to provide the Data to the Owner.
          </Text>
          <H3 mb="20px" mt="20px">
            Methods of processing
          </H3>
          <Text>
            The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the
            Data. The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly
            related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in
            charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external
            parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies)
            appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
          </Text>
          <H3 mb="20px" mt="20px">
            Legal basis of processing
          </H3>
          <Text>
            The Owner may process Personal Data relating to Users if one of the following applies: Users have given their consent for one or more
            specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such
            processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply,
            whenever the processing of Personal Data is subject to European data protection law; provision of Data is necessary for the performance of
            an agreement with the User and/or for any pre-contractual obligations thereof; processing is necessary for compliance with a legal
            obligation to which the Owner is subject; processing is related to a task that is carried out in the public interest or in the exercise of
            official authority vested in the Owner; processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a
            third party. In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
          </Text>
          <H3 mb="20px" mt="20px">
            Place
          </H3>
          <Text>
            The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
            Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out
            more about the place of processing of such transferred Data, Users can check the section containing details about the processing of
            Personal Data. Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any
            international organization governed by public international law or set up by two or more countries, such as the UN, and about the security
            measures taken by the Owner to safeguard their Data. If any such transfer takes place, Users can find out more by checking the relevant
            sections of this document or inquire with the Owner using the information provided in the contact section.
          </Text>
          <H3 mb="20px" mt="20px">
            Retention time
          </H3>
          <Text>
            Personal Data shall be processed and stored for as long as required by the purpose they have been collected for. Therefore: Personal Data
            collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has
            been fully performed. Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to
            fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant
            sections of this document or by contacting the Owner. The Owner may be allowed to retain Personal Data for a longer period whenever the
            User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain
            Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority. Once
            the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to
            rectification and the right to data portability cannot be enforced after expiration of the retention period.
          </Text>
          <H3 mb="20px" mt="20px">
            The purposes of processing
          </H3>
          <Text>
            The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to
            enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent
            activity, as well as the following: Analytics. For specific information about the Personal Data used for each purpose, the User may refer
            to the section “Detailed information on the processing of Personal Data”. Detailed information on the processing of Personal Data Personal
            Data is collected for the following purposes and using the following services: Analytics
          </Text>

          <H3 mb="20px" mt="20px">
            The rights of Users
          </H3>
          <Text>
            Users may exercise certain rights regarding their Data processed by the Owner. In particular, Users have the right to do the following:
            Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the
            processing of their Personal Data. Object to processing of their Data. Users have the right to object to the processing of their Data if
            the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below. Access
            their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the
            processing and obtain a copy of the Data undergoing processing. Verify and seek rectification. Users have the right to verify the accuracy
            of their Data and ask for it to be updated or corrected. Restrict the processing of their Data. Users have the right, under certain
            circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than
            storing it. Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the
            erasure of their Data from the Owner. Receive their Data and have it transferred to another controller. Users have the right to receive
            their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another
            controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the
            processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof. Lodge a
            complaint. Users have the right to bring a claim before their competent data protection authority. Details about the right to object to
            processing Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the
            purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their
            particular situation to justify the objection. Users must know that, however, should their Personal Data be processed for direct marketing
            purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing
            Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
          </Text>
          <H3 mb="20px" mt="20px">
            How to exercise these rights
          </H3>
          <Text>
            Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests
            can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month. Additional
            information about Data collection and processing
          </Text>
          <H3 mb="20px" mt="20px">
            Legal action
          </H3>
          <Text>
            The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising
            from improper use of this Application or the related Services. The User declares to be aware that the Owner may be required to reveal
            personal data upon request of public authorities.
          </Text>
          <H3 mb="20px" mt="20px">
            Additional information about User's Personal Data
          </H3>
          <Text>
            In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual
            information concerning particular Services or the collection and processing of Personal Data upon request.
          </Text>
          <H3 mb="20px" mt="20px">
            System logs and maintenance
          </H3>
          <Text>
            For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this
            Application (System logs) use other Personal Data (such as the IP Address) for this purpose.
          </Text>
          <H3 mb="20px" mt="20px">
            Information not contained in this policy
          </H3>
          <Text>
            More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact
            information at the beginning of this document.
          </Text>

          <H3 mb="20px" mt="20px">
            How “Do Not Track” requests are handled
          </H3>
          <Text>
            This Application does not support “Do Not Track” requests. To determine whether any of the third-party services it uses honor the “Do Not
            Track” requests, please read their privacy policies.
          </Text>
          <H3 mb="20px" mt="20px">
            LiveRamp
          </H3>
          <Text>
            When you log into our website, we may share information that we collect from you, such as your email (in hashed, pseudonymous form), IP
            address, or information about your browser or operating system, with our partner LiveRamp. LiveRamp uses this information to create an
            online identification code for the purpose of recognizing you on your device. This code does not contain any of your identifiable personal
            data and cannot be used to re-identify you. We place this code in our cookie and allow it to be used for online and cross-channel
            advertising. It may be shared with our advertising partners and other third party advertising companies globally for the purpose of
            enabling interest-based content or targeted advertising throughout your online experience (e.g. web, email, connected devices, and in-app,
            etc). These third parties may in turn use this code to link demographic or interest-based information you have provided in your
            interactions with them. You have the right to express a choice regarding our sharing of this data with LiveRamp for the above purposes, of
            the creation of this code, or of our sharing of the code with our advertising partners.
          </Text>
          <H3 mb="20px" mt="20px">
            Changes to this privacy policy
          </H3>
          <Text>
            The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within
            this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to
            the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
            Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the
            User, where required.
          </Text>
        </Flex>
      </FullHeightContainer>
      <Footer iso2={'en'} />
    </Layout>
  )
}
export default PrivacyPolicy

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
